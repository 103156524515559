import React from 'react';
import { Outlet } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import Box from '@mui/material/Box';

import { useFeatureToggle, FEATURE_TOGGLES } from 'sharedComponents/FeatureToggle';

import Nav from '../../components/Navigation';
import Footer from '../../components/Footer';
import GENav from '../../components/NavigationBar';
import Container from './Container';
import Toasts from './Toasts';
import Initialize from './Initialize';

const AppWrapper = () => {
  const geThemeFeature = useFeatureToggle(FEATURE_TOGGLES.GE_THEME);
  return (
    <QueryParamProvider adapter={ReactRouter6Adapter}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Initialize>
          <Container>
            {geThemeFeature ? <GENav /> : <Nav />}
            <Toasts />
            <Box
              component="main"
              sx={{
                zIndex: 0,
                display: 'grid',
                gridTemplateColumns: 'auto 1fr',
                width: '100vw',
                pt: geThemeFeature && 8,
                pb: geThemeFeature && '40px',
              }}
            >
              <Outlet />
              {geThemeFeature && <Footer />}
            </Box>
          </Container>
        </Initialize>
      </LocalizationProvider>
    </QueryParamProvider>
  );
};

export default AppWrapper;
