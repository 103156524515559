import React, { Suspense } from 'react';
import { IntlProvider } from 'react-intl';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useMultiScreenRegister, useMultiScreenSettings } from 'sharedComponents/MultiScreen';
import MessageBox from 'sharedComponents/MessageBox';
import useLocaleMessages from '../../../locales/locales';
import AppFallback from '../../components/Fallback/AppFallback';
import Router from 'App/routes/Router';

import 'mapbox-gl/dist/mapbox-gl.css';
import 'moment/locale/nb';

const App = ({ locale, user, organizationShortName }) => {
  const [localeMessages, error] = useLocaleMessages(locale, organizationShortName);
  useMultiScreenRegister();
  useMultiScreenSettings();

  if (!localeMessages && error) {
    return <MessageBox message="Something went wrong. Try again later or contact us for help." />;
  }
  if (!localeMessages) {
    return null;
  }

  moment.updateLocale('en', {
    week: {
      dow: 1,
    },
  });

  return (
    <IntlProvider locale={locale} key={locale} messages={localeMessages}>
      <Suspense fallback={<AppFallback />}>
        <Router />
      </Suspense>
    </IntlProvider>
  );
};

App.propTypes = {
  locale: PropTypes.string.isRequired,
};

export default App;
