import React, { useCallback } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate } from 'react-router-dom';
import DateFormatter from 'sharedComponents/DateFormatter';
import Chip from '@mui/material/Chip';
import { Tooltip } from '@mui/material';

const TaskResourceItem = ({ issue }) => {
  const navigate = useNavigate();
  const goTo = useCallback(() => {
    const locationMrId = issue.targetObject.sourceObjects[0].sourceObjects.find(
      so => so.__typename === 'UsagePointLocationDto'
    ).mrId;
    navigate('/smoc/map?showIssues=1&markedAssets=USAGE_POINTS:' + locationMrId);
  }, [navigate, issue]);
  return (
    <ListItemButton onClick={goTo}>
      <ListItemText
        primary={
          <>
            <Tooltip title={issue.description}>
              <Chip sx={{ mr: 2 }} label={issue.category} color="warning" />
            </Tooltip>
            <DateFormatter date={issue.createdDateTime} variant="dateTime" />
          </>
        }
      />
    </ListItemButton>
  );
};

export default TaskResourceItem;
