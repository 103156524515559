import React, { useState, useCallback } from 'react';
import { useIntl } from 'react-intl';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FormattedMessage } from 'react-intl';
import { isConfirmLogoutDisabled, setDisableConfirmLogout } from 'App/api/localStorage';
import ConfirmDialog from 'sharedComponents/ConfirmDialog';
import { MenuItem } from '@mui/material';

const Logout = ({ logout }) => {
  const [disableConfirm, setDisableConfirm] = useState(false);
  const intl = useIntl();
  const onLogoutClick = useCallback(() => {
    if (disableConfirm) {
      setDisableConfirmLogout(true);
    }
    logout();
  }, [disableConfirm, logout]);
  if (isConfirmLogoutDisabled()) {
    return (
      <MenuItem sx={{ color: 'error.main' }} onClick={logout}>
        <FormattedMessage id="Logout-modalButton-confirmLogout" />
      </MenuItem>
    );
  }
  return (
    <ConfirmDialog
      title={intl.formatMessage({ id: 'Logout-modalHeader-confirmLogout', defaultMessage: 'Confirm logout' })}
      contentText={intl.formatMessage({
        id: 'Logout-modalContent-confirmLogout',
        defaultMessage: 'Are you sure you want to log out?',
      })}
      content={
        <FormControlLabel
          control={<Checkbox onChange={() => setDisableConfirm(prev => !prev)} checked={disableConfirm} />}
          sx={{
            mt: 2,
          }}
          label={<FormattedMessage id="Logout-modalCheckbox-doNotAskAgain" defaultMessage="Don't ask again" />}
        />
      }
      confirmText={intl.formatMessage({ id: 'Logout-modalButton-confirmLogout', defaultMessage: 'Log out' })}
      onConfirm={onLogoutClick}
    >
      <MenuItem sx={{ color: 'error.main' }}>
        <FormattedMessage id="Logout-modalButton-confirmLogout" />
      </MenuItem>
    </ConfirmDialog>
  );
};

export default Logout;
