import React, { Fragment, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

const styleSx = {
  info: {
    color: 'info.main',
  },
  success: {
    color: 'primary.dark',
  },
  warning: {
    color: 'warning.main',
  },
  danger: {
    color: 'error.main',
  },
  error: {
    color: 'error.main',
  },
};

const ConfirmDialog = ({
  title,
  confirmText,
  contentText,
  content,
  cancelText,
  children,
  variant,
  maxWidth,
  onConfirm,
  onCancel,
  fullWidth,
  disabled,
}) => {
  const [open, setOpen] = useState(false);
  const intl = useIntl();
  const onToggle = useCallback(
    isConfirmed => {
      setOpen(prev => !prev);
      if (isConfirmed === true) {
        onConfirm();
      } else if (onCancel && !open) {
        onCancel();
      }
    },
    [onCancel, onConfirm, open]
  );
  const clickHandlerChild = React.cloneElement(children, { onClick: onToggle });
  return (
    <Fragment>
      {clickHandlerChild}
      <Dialog maxWidth={maxWidth} fullWidth={fullWidth} open={open} onClose={() => setOpen(false)}>
        <DialogTitle
          children={
            title ||
            intl.formatMessage({ id: 'ButtonConfirmDialog-title-confirmDelete', defaultMessage: 'Confirm deletion' })
          }
        />
        <DialogContent>
          {contentText && <DialogContentText children={contentText} />}
          {content}
        </DialogContent>
        <DialogActions>
          <Button
            color="inherit"
            onClick={() => onToggle(false)}
            children={
              cancelText || intl.formatMessage({ id: 'ButtonConfirmDialog-button-cancel', defaultMessage: 'Cancel' })
            }
          />
          <Button disabled={disabled} onClick={() => onToggle(true)} sx={styleSx[variant]}>
            {confirmText || intl.formatMessage({ id: 'ButtonConfirmDialog-button-confirm', defaultMessage: 'Delete' })}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

ConfirmDialog.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(['info', 'success', 'warning', 'danger']),
  titleIntlId: PropTypes.string,
  contentIntlId: PropTypes.string,
  content: PropTypes.node,
  confirmIntlId: PropTypes.string,
  cancelIntlId: PropTypes.string,
};

ConfirmDialog.defaultProps = {
  onCancel: undefined,
  variant: 'danger',
};

export default ConfirmDialog;
