import { combineReducers } from 'redux';
import { reducer as configuration, storeKey as configurationKey } from './configuration';
import { reducer as workflows, storeKey as workflowsKey } from './workflows';
import { reducer as workflowsArchive, storeKey as workflowsArchiveKey } from './workflowsArchive';

const datalake = combineReducers({
  [configurationKey]: configuration,
  [workflowsKey]: workflows,
  [workflowsArchiveKey]: workflowsArchive,
});

export default datalake;
