import React, { useMemo } from 'react';
import { oneOf, string, object, number } from 'prop-types';
import useDateFormatter from './useDateFormatter';
import { VARIANTS } from './useDateFormatter';
import { oneOfType } from 'prop-types';

const DateFormatter = ({ date, sourceTimeZone, destinationTimeZone, variant, format, ...props }) => {
  const dateFormatter = useDateFormatter();
  const dateString = useMemo(() => {
    if (variant === 'iso') {
      return dateFormatter.iso(date, sourceTimeZone, destinationTimeZone);
    }
    return dateFormatter.format(date, sourceTimeZone, destinationTimeZone)(format || VARIANTS[variant]);
  }, [dateFormatter, date, sourceTimeZone, destinationTimeZone, variant, format]);
  return <span {...props}>{dateString}</span>;
};

DateFormatter.propTypes = {
  variant: oneOf(['time', 'dateTime', 'date', 'timestamp', 'iso', 'time-iso']),
  sourceTimeZone: string,
  destinationTimeZone: string,
  format: string,
  date: oneOfType([string, object, number]),
};

export default DateFormatter;
