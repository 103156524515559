import { selectors as authSelectors } from 'App/ducks/authDuck';
import { selectors as userSelectors } from 'App/ducks/userDuck';
import { selectors as configurationSelectors } from 'App/ducks/configurationDuck';
import { creators as issuesDtoCreators } from '../ducks/issueDto';
import { fetchIssueDtoInsidePolygonList, fetchIssueDtoList, fetchIssueDtoMrIdList } from '../api/issueDto';

export const fetchIssueDtoAction = (
  first,
  offset,
  source,
  mappedFilters,
  mappedTargetObjectFilter,
  order,
  polygon
) => async (dispatch, getState) => {
  const state = getState();
  const token = authSelectors.token(state);
  const organization = userSelectors.organizationShortName(state);
  const dataLakeGraphqlHost = configurationSelectors.configuration(state).dataLakeGraphqlHost;
  try {
    dispatch(issuesDtoCreators.fetch());
    if (polygon && polygon.length) {
      const result = await fetchIssueDtoInsidePolygonList(
        dataLakeGraphqlHost,
        token,
        organization,
        500,
        0,
        source,
        mappedFilters,
        mappedTargetObjectFilter,
        polygon
      );
      const mappedIssues = result.usagePointLocationWithIssues
        .map(location => location.targetObjects[0].targetObjects[0].issues)
        .flat();
      dispatch(issuesDtoCreators.fetchSuccess(mappedIssues));
      dispatch(issuesDtoCreators.setCount(mappedIssues.length));
    } else {
      const result = await fetchIssueDtoList(
        dataLakeGraphqlHost,
        token,
        organization,
        first,
        offset,
        source,
        mappedFilters,
        mappedTargetObjectFilter,
        order
      );
      dispatch(issuesDtoCreators.fetchSuccess(result.issueDto));
      dispatch(issuesDtoCreators.setCount(result.aggregateIssueDto.count));
    }
  } catch (e) {
    dispatch(issuesDtoCreators.fetchFailed(e));
  }
};

export const fetchIssueDtoListAction = (mrIds, source) => async (dispatch, getState) => {
  const state = getState();
  const token = authSelectors.token(state);
  const organization = userSelectors.organizationShortName(state);
  const dataLakeGraphqlHost = configurationSelectors.configuration(state).dataLakeGraphqlHost;
  try {
    const result = await fetchIssueDtoMrIdList(dataLakeGraphqlHost, token, organization, mrIds, source);
    return result.issueDto;
  } catch (e) {
    return [];
  }
};
