import { performQuery } from '../util/graphql-client';

const queryIssueDtoMrIdList = {
  operationName: 'queryIssueDto',
  query: `
    query queryIssueDto($mrIds: [String], $source: String!) {
      issueDto: queryIssueDto(
        filter: {
            mrId: {
                in: $mrIds
            },
            source: {
              eq: $source
            }
        }
      ) {
        mrId
        source
        category
        description
        createdDateTime
        effectiveDateTime
        endDateTime
        targetObject {
          __typename
          mrId
          sourceObjects {
            mrId
            sourceObjects {
              ... on UsagePointLocationDto {
                mrId
                __typename
                centroid {
                    latitude
                    longitude
                }
              }
            }
          }
        }
      }
  }
`,
};

const queryIssueDtoMap = {
  operationName: 'queryIssueDto',
  query: `
    query queryIssueDto($first: Int, $offset: Int, $source: String!, $mappedFilters: [IssueDtoFilter], $mappedTargetObjectFilter: [IdentifiedObjectFilter]) {
      issueDto: queryIssueDto(
        order:{desc:effectiveDateTime}
        first: $first
        offset: $offset
        filter: {
            source: {
                eq: $source
            },
            and: $mappedFilters
        }
      ) @cascade(fields: ["targetObject"]) {
        mrId
        source
        category
        description
        createdDateTime
        effectiveDateTime
        endDateTime
        targetObject (filter: { or: $mappedTargetObjectFilter }) {
          __typename
          mrId
          sourceObjects {
            mrId
            sourceObjects {
              ... on UsagePointLocationDto {
                mrId
                __typename
                centroid {
                    latitude
                    longitude
                }
              }
            }
          }
        }
      }
  }
`,
};

const queryIssueDtoList = {
  operationName: 'queryIssueDto',
  query: `
    query queryIssueDto($first: Int, $offset: Int, $mappedFilters: [IssueDtoFilter], $mappedTargetObjectFilter: [IdentifiedObjectFilter], $order: IssueDtoOrder) {
      issueDto: queryIssueDto(
        order: $order
        first: $first
        offset: $offset
        filter: {
            and: $mappedFilters
        }
      ) @cascade(fields: ["targetObject"]) {
        source
        category
        description
        createdDateTime
        effectiveDateTime
        endDateTime
        mrId
        targetObject (filter: { or: $mappedTargetObjectFilter })  {
          __typename
          mrId
          sourceObjects {
            __typename
            sourceObjects {
                ... on UsagePointLocationDto {
                    __typename
                    mrId
                }
            }
          }
        }
      }
      aggregateIssueDto: aggregateIssueDto(
        filter: {
            and: $mappedFilters
        }
      ) {
        count
      }
  }
    `,
};

const queryIssueDtoInsidePolygonList = {
  operationName: 'queryUsagePointLocationDto',
  query: `
query queryUsagePointLocationDto($first: Int, $offset: Int, $source: String!, $mappedFilters: [IssueDtoFilter], $mappedTargetObjectFilter: [IdentifiedObjectFilter], $polygon: [PointRef!]!) {
    usagePointLocationWithIssues: queryUsagePointLocationDto(
        first: $first,
        offset: $offset,
        filter: {
            source: {eq: $source},
            centroid: {
                within: {
                    polygon: {
                        coordinates: {
                            points: $polygon
                        }
                    }
                }
            }
        }
    ) @cascade {
    mrId
    centroid {
        longitude
        latitude
    }
    targetObjects @cascade {
        targetObjects (filter: { or: $mappedTargetObjectFilter }) @cascade {
            issues(filter: { and: $mappedFilters }) @cascade(fields: ["mrId"]) {
                mrId
                source
                category
                description
                createdDateTime
                effectiveDateTime
                endDateTime
                targetObject {
                  __typename
                  mrId
                  sourceObjects {
                    __typename
                    sourceObjects {
                        ... on UsagePointLocationDto {
                            __typename
                            mrId
                        }
                    }
                  }
                }
            }
        }
    }
}
}
`,
};

export const fetchIssueDtoMap = (
  host,
  token,
  organization,
  first,
  offset,
  source,
  mappedFilters,
  mappedTargetObjectFilter
) =>
  performQuery(host, token, queryIssueDtoMap, {
    organization,
    first,
    offset,
    source,
    mappedFilters,
    mappedTargetObjectFilter,
  });

export const fetchIssueDtoMrIdList = (host, token, organization, mrIds, source) =>
  performQuery(host, token, queryIssueDtoMrIdList, {
    organization,
    mrIds,
    source,
  });

export const fetchIssueDtoList = (
  dataLakeGraphqlHost,
  token,
  organization,
  first,
  offset,
  source,
  mappedFilters,
  mappedTargetObjectFilter,
  order
) =>
  performQuery(dataLakeGraphqlHost, token, queryIssueDtoList, {
    organization,
    first,
    offset,
    source,
    mappedFilters,
    mappedTargetObjectFilter,
    order,
  });

export const fetchIssueDtoInsidePolygonList = (
  dataLakeGraphqlHost,
  token,
  organization,
  first,
  offset,
  source,
  mappedFilters,
  mappedTargetObjectFilter,
  polygon
) =>
  performQuery(dataLakeGraphqlHost, token, queryIssueDtoInsidePolygonList, {
    organization,
    first,
    offset,
    polygon,
    source,
    mappedFilters,
    mappedTargetObjectFilter,
  });
